





























































































































































































import { Component, Vue } from "vue-property-decorator";
import ErrorService, { ValidationErrors } from "@/services/errors";
import AlertBox from "@/components/AlertBox.vue";
import BackButton from "@/components/BackButton.vue";
import { AxiosError, AxiosResponse } from "axios";
import sentry, { captureSentryException } from "@/plugins/sentry";
import { Getter } from "vuex-class";
import { User } from "@/models/user";
import SocialButtons from "@/components/SocialButtons.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import Referral from "@/models/referral";
import ReferralApi from "@/services/api/referral";
import { ensureAuthenticated } from "@/plugins/firebase";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import TextLoadingSpinner from "@/components/TextLoadingSpinner.vue";
import ShareButton from "@/components/ShareButton.vue";
import { ApiResponse } from "@/services/api/axios";

@Component({
    components: {
        ShareButton,
        TextLoadingSpinner,
        LoadingSpinner,
        LoadingButton,
        SocialButtons,
        BackButton,
        AlertBox,
    },
})
export default class ReferralsIndex extends Vue {
    formPhoneNumber: string = "";
    isSubmitting: boolean = false;
    formInputErrors: ValidationErrors = new ValidationErrors();
    referral: Referral | undefined = undefined;

    @Getter("user") user!: User | null;

    handleError(error: Error) {
        sentry.captureException(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.formInputErrors = ErrorService.getValidationErrors(error);
        this.handleError(
            new Error(error.response?.data?.message ?? error.message)
        );
    }

    updatePhoneNumber() {
        this.isSubmitting = true;
        this.formInputErrors = new ValidationErrors();
        this.$recaptcha("update_payment_phone_number")
            .then((captcha) => {
                ReferralApi.update(
                    this.user?.uid as string,
                    this.formPhoneNumber,
                    captcha
                )
                    .then((response: AxiosResponse) => {
                        this.$root.$emit(
                            this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                            response.data?.message || response.statusText
                        );
                    })
                    .catch((error: AxiosError<ApiResponse>) => {
                        this.formInputErrors =
                            ErrorService.getValidationErrors(error);
                        this.handleAxiosError(error);
                    })
                    .finally(() => {
                        this.isSubmitting = false;
                    });
            })
            .catch((error: Error) => {
                this.handleError(error);
                this.isSubmitting = false;
            });
    }

    loadReferral() {
        this.isSubmitting = true;
        if (!this.user?.uid) {
            captureSentryException(
                new Error("cannot load referral because user has no id")
            );
        }

        ReferralApi.show(this.user?.uid as string)
            .then((response: AxiosResponse) => {
                this.referral = new Referral(response.data.data);
                this.formPhoneNumber = this.referral.phoneNumber;
            })
            .catch(this.handleError)
            .finally(() => {
                this.isSubmitting = false;
            });
    }

    mounted() {
        ensureAuthenticated().finally(() => {
            this.loadReferral();
        });
    }
}
